export const config = {
    java_api_url: "https://client.baltika.cryptoveche.ru:443/api",
    admin_url: "https://admin.baltika.cryptoveche.ru",
    ws_connect: "wss://client.baltika.cryptoveche.ru/ws",
    ws_user: "client",
    ws_pass: "client",
    enable_esia: false,
    confidentiality: "https://docs.cryptoveche.ru/files/cryptoveche_pk2.pdf",
    type: 'main'
};
